import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { AI_CHAT_CORE_FIELDS_FRAGMENT } from '@coachmate/ai/graphql/ai-chat-fragment.graphql'
import { GqlCoreAiChatFieldsFragment, GqlCreateAiChatInput, GqlCreateAiChatMutation, GqlCreateAiChatMutationVariables } from '@graphql'

const CREATE_AI_CHAT = gql`
  ${AI_CHAT_CORE_FIELDS_FRAGMENT}

  mutation CreateAiChat($input: CreateAiChatInput!) {
    createAiChat(input: $input) {
      ...CoreAiChatFields
    }
  }
`

export const createAiChat = async (input: GqlCreateAiChatInput) => {
  loggerService.debug('[createAiChat] Create Ai chat.')

  const { data } = await apolloService.mutate<GqlCreateAiChatMutation, GqlCreateAiChatMutationVariables>({
    mutation: CREATE_AI_CHAT,
    variables: { input },
  })

  return data?.createAiChat as GqlCoreAiChatFieldsFragment
}

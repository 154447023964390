import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'

type Props = {
  className?: string
  children?: ReactNode
  isRounded?: boolean
  isLoading?: boolean
}

export const Skeleton = memo(({ className, children, isRounded, isLoading }: Props) => {
  const classes = classNames(className, {
    'rounded-lg': !isRounded,
    'rounded-full': isRounded,
    'animate-pulse': isLoading,
  })

  return <div className={classes}>{children}</div>
})

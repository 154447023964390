import { Target, Transition } from 'framer-motion'
import { TRANSITION_DURATION, TransitionType } from '@coachmate/common'

class TransitionService {
  getTransitionConfig = (transitionType?: TransitionType, xOffset = 0) => {
    const initial: Target = { opacity: 0, scaleX: 1, scaleY: 1 }
    const animate: Target = { opacity: 1, x: 0 }
    const exit: Target = { opacity: 0, scaleX: 1, scaleY: 1 }
    const transition: Transition = { duration: transitionType ? TRANSITION_DURATION.sm : 0 }

    if (transitionType) {
      if (transitionType === 'left') {
        initial.x = xOffset - 30
        exit.x = xOffset + 30
      } else if (transitionType === 'right') {
        initial.x = xOffset + 30
        exit.x = xOffset - 30
      }
    }

    return { initial, exit, animate, transition }
  }
}

export const transitionService = new TransitionService()

import { AI_PATHS } from '@coachmate/ai/ai.routes'
import { AUTH_PATHS } from '@coachmate/auth/auth.routes'
import { TEAM_PATHS } from '@coachmate/team/team.routes'
import { DOWNLOAD_PATHS } from '@coachmate/download/download.routes'

export type RouterPath =
  | '/'
  | '/error'
  | (typeof AI_PATHS)[number]
  | (typeof AUTH_PATHS)[number]
  | (typeof TEAM_PATHS)[number]
  | (typeof DOWNLOAD_PATHS)[number]

export const ROUTES = [...AI_PATHS, ...AUTH_PATHS, ...TEAM_PATHS, ...DOWNLOAD_PATHS] as const

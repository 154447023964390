import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlSendResetPasswordEmailInput, GqlSendResetPasswordEmailMutation, GqlSendResetPasswordEmailMutationVariables } from '@graphql'

const SEND_RESET_PASSWORD_EMAIL_BY_TOKEN = gql`
  mutation SendResetPasswordEmail($input: SendResetPasswordEmailInput!) {
    sendResetPasswordEmail(input: $input)
  }
`

export const sendResetPasswordEmail = async (input: GqlSendResetPasswordEmailInput) => {
  loggerService.debug('[sendResetPasswordEmail] Sending reset password email.')

  const { data } = await apolloService.mutate<GqlSendResetPasswordEmailMutation, GqlSendResetPasswordEmailMutationVariables>({
    mutation: SEND_RESET_PASSWORD_EMAIL_BY_TOKEN,
    variables: { input },
  })

  return data?.sendResetPasswordEmail
}

import { useState } from 'react'
import { isNumber } from 'lodash'
import { useCommon } from '@coachmate/common'

export type TransitionType = 'left' | 'right'

export type OnTransitionParam = {
  step?: number
  transition?: TransitionType
}

type Props = {
  initialStep?: number
  initialTransition?: TransitionType
}

export const useTransition = ({ initialStep = 1, initialTransition = 'right' }: Props = {}) => {
  const { setUi } = useCommon()
  const [step, setStep] = useState(initialStep)
  const [transition, setTransition] = useState<TransitionType>(initialTransition)

  const handleTransition = ({ step, transition = 'right' }: OnTransitionParam) => {
    setUi('saving')
    setTransition(transition)

    if (isNumber(step)) {
      // Giving the page time for the transition value to drill through to the nested component. For some reason this can be delayed.
      setTimeout(() => setStep(step), 0)
    }

    // Giving the page time to transition before enabling the submit button again to prevent user spamming the submit button, and skipping some of
    // the steps.
    setTimeout(() => setUi(null), 200)
  }

  return { step, transition, onTransition: handleTransition }
}

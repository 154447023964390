import classNames from 'classnames'
import { find, indexOf, map } from 'lodash'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { TabModel, TabsVariant } from '@coachmate/common'

type Props<T> = {
  className?: string
  id: T
  label: ReactNode
  variant?: TabsVariant
  activeTab: TabModel<T>
  setActiveTab: (activeTab: TabModel<T>) => void
  tabs: TabModel<T>[]
  isFull?: boolean
  isDisabled?: boolean
}

function TabButtonInner<T>(props: Props<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const { className, id, label, variant, activeTab, setActiveTab, tabs, isFull, isDisabled } = props
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id)

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`)
  }

  const isActive = activeTab.id === id
  let classes = classNames(className, 'flex items-center justify-center text-sm text-white w-full focus:outline-none', {
    'mr-1': !isFull && variant !== 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'mr-5': !isFull && variant === 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'is-active': isActive,
    'pointer-events-none opacity-50': isDisabled,
  })

  if (variant === 'underline') {
    classes = classNames(classes, 'py-2.5 border-b-1 border-transparent', {
      'text-opacity-90': isActive,
      'text-opacity-40': !isActive,
    })
  }

  if (variant !== 'underline') {
    classes = classNames(classes, 'text-opacity-90 rounded-full h-7 px-3 z-1')
  }

  const handleClick = () => {
    // If there are only 2 tabs, always switch the tab.
    setActiveTab(tabs.length === 2 ? find(tabs, (tabToActivate) => tabToActivate.id !== activeTab.id) || tab : tab)
  }

  return (
    <button className={classes} onClick={handleClick} type="button" disabled={isDisabled} ref={ref}>
      {/* Wrapping the content in a div, so we can get the exact width of the content without having to hard code the padding offset. */}
      <div>{label}</div>
    </button>
  )
}

export const TabButton = forwardRef(TabButtonInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TabButtonInner>

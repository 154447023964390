import { map } from 'lodash'
import classNames from 'classnames'
import React, { HTMLProps } from 'react'
import { Icon } from '@coachmate/common'

export type SelectOption<T = string> = {
  label: string
  value: T
}

type Props<T> = Omit<HTMLProps<HTMLSelectElement>, 'onChange' | 'required' | 'disabled' | 'size'> & {
  onChange: (value: SelectOption<T>['value']) => void
  options: SelectOption<T>[]
  size?: 'sm' | 'md'
  isDisabled?: boolean
  isError?: boolean
  isRequired?: boolean
}

export function Select<T extends string>(props: Props<T>) {
  const { className, options, onChange, size = 'md', isError, isDisabled, isRequired, value, ...rest } = props
  const classes = classNames(className, 'relative', {
    'pointer-events-none opacity-50': isDisabled,
  })
  const selectClasses = classNames(
    'block bg-ui-900 text-white text-opacity-90 border relative rounded-md w-full appearance-none focus:shadow-none focus:outline-none',
    {
      'px-3 py-2': size === 'md',
      'px-2 py-1.5': size === 'sm',
      'border-ui-700 focus:border-primary-500': !isError,
      'border-danger focus:border-danger': isError,
    }
  )

  return (
    <div className={classes}>
      <select
        {...rest}
        className={selectClasses}
        onChange={({ target }) => onChange(target.value as T)}
        value={value || options[0].value}
        disabled={isDisabled}
        required={isRequired}
      >
        {map(options, ({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <Icon className="absolute right-3 top-1/2 -translate-y-1/2" icon="chevron-down" variant="primary-1" width={12} height={6} />
    </div>
  )
}

import { gql } from '@apollo/client'

export const AI_CHAT_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreAiChatFields on AiChat {
    id
    userId
    conversationId
    question
    answer
  }
`

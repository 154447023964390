export const ERROR_MESSAGE = {
  accountAlreadyExists: 'An account like this already exists.',
  apolloNotInitialised: 'Apollo not initialised.',
  authentication: 'Authentication failed.',
  contentfulNotInitialised: 'Contentful not initialised.',
  emailAlreadyExists: 'An account with that email already exists.',
  firebaseNotInitialised: 'Firebase not initialised.',
  invalidEmail: 'We cannot create an account with that email.',
  invalidPageSize: 'Page size cannot be greater than 100.',
  invalidPassword: 'The password must be a string with at least 6 characters.',
  invalidUsername: 'We cannot create an account with that user name.',
  noAccount: 'Account does not exist.',
  noAccountEntity: 'Account does not have an entity.',
  noAiCompletion: 'AI had no completion response.',
  noArticle: 'Article does not exist.',
  noCoachingTip: 'Coaching tip does not exist.',
  noDrill: 'Drill does not exist.',
  noEntity: 'Entity does not exist.',
  noEntityWithSportAndRegion: 'Entity with passed sport and region does not exist.',
  noLesson: 'Lesson does not exist.',
  noLessonCoachingTips: 'Lesson does not contain any coaching tips.',
  noMiniCourse: 'Mini course does not exist.',
  noMiniCourseLessons: 'Mini course does not contain any lessons.',
  noPractice: 'Practice does not exist.',
  noPracticePlan: 'Practice plan does not exist.',
  noPracticeReview: 'Practice review1 does not exist.',
  noProgramme: 'Programme does not exist.',
  noSuperuserAccount: 'Superuser account does not exist.',
  noTeam: 'Team does not exist.',
  noUser: 'User does not exist.',
  permission: 'You do not have permission to perform this action.',
  practiceComplete: 'This practice is already complete.',
  tooManyRequests: 'Too many requests',
  unknown: 'Unknown error.',
}

import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { GqlSignUpUserInput, GqlSignUpUserMutation, GqlSignUpUserMutationVariables } from '@graphql'

const SIGN_UP_USER = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  mutation SignUpUser($input: SignUpUserInput!) {
    signUpUser(input: $input) {
      ...CoreUserFields
    }
  }
`

export const signUpUser = async (input: GqlSignUpUserInput) => {
  loggerService.debug('[signUpUser] Signing up user.')

  const { data } = await apolloService.mutate<GqlSignUpUserMutation, GqlSignUpUserMutationVariables>({
    mutation: SIGN_UP_USER,
    variables: { input },
  })

  return data?.signUpUser
}

export const COLOUR = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  success: '#27AE60',
  danger: '#EB5757',
  warning: '#FACC15',
  info: '#3B82F6',
  primary: {
    50: '#F2FAFD',
    100: '#F2FAFD',
    200: '#CAECF7',
    300: '#B0E3F2',
    400: '#91D8EE',
    500: '#6DCCE8',
    600: '#34B7DF',
    700: '#1E9CC2',
    800: '#177996',
    900: '#0C3C4B',
  },
  ui: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  accents: {
    red: { light: '#FECDD3', base: '#F43F5E', dark: '#9F1239' },
    fuchsia: { light: '#F5D0FE', base: '#D946EF', dark: '#86198F' },
    purple: { light: '#DDD6FE', base: '#8B5CF6', dark: '#5B21B6' },
    blue: { light: '#BFDBFE', base: '#3B82F6', dark: '#1E40AF' },
    cyan: { light: '#A5F3FC', base: '#06B6D4', dark: '#155E75' },
    emerald: { light: '#A7F3D0', base: '#10B981', dark: '#065F46' },
    lime: { light: '#D9F99D', base: '#84CC16', dark: '#3F6212' },
    amber: { light: '#FDE68A', base: '#F59E0B', dark: '#92400E' },
  },
} as const

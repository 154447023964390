import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from '@coachmate/user'
import { routerService } from '@coachmate/router'
import CoachMateLogoLight from '@assets/svg/coachmate-logo-light.svg'
import { Avatar, Button, Dropdown, DropdownContent, Header, Icon, Text, utilService } from '@coachmate/common'

export const AppHeader = memo(() => {
  const { user } = useUser()
  const history = useHistory()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <Header className="bg-ui-800">
      <div className="flex items-center justify-between w-full h-full">
        <div className="flex items-center h-full mr-16">
          <div className="flex items-center justify-center">
            <CoachMateLogoLight className="h-6 mr-4" />
          </div>
        </div>

        {user && (
          <div className="relative">
            <Button className="flex items-center justify-center" onClick={() => setIsDropdownOpen(true)} state="raw">
              <Avatar className="bg-ui-700 mr-2" size="md">
                {utilService.getInitials(user)}
              </Avatar>
              <Text>{utilService.getFullName(user)}</Text>
            </Button>
            <Dropdown className="top-14 -right-2" onClickOutside={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent onClick={() => history.push(routerService.getHref('/logout'))}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="logout-outlined" variant="primary-3" />
                  <Text>Logout</Text>
                </div>
              </DropdownContent>
            </Dropdown>
          </div>
        )}
      </div>
    </Header>
  )
})

import classNames from 'classnames'
import React, { memo, useState } from 'react'
import { TeamPill, teamService } from '@coachmate/team'
import { Button, Icon, Text, utilService } from '@coachmate/common'
import { GqlCoreTeamFieldsFragment, GqlCoreEntityFieldsFragment } from '@graphql'

type Props = {
  className?: string
  team: GqlCoreTeamFieldsFragment
  entity?: GqlCoreEntityFieldsFragment | null
}

export const TeamCard = memo(({ className, team, entity }: Props) => {
  const classes = classNames(className, 'text-center rounded-lg p-5')
  const [isCopied, setIsCopied] = useState(false)

  const handleCodeClick = () => {
    if (!team.code) {
      return
    }

    navigator.clipboard.writeText(team.code)
    setIsCopied(true)
  }

  return (
    <div key={team.id} className={classes} style={{ backgroundColor: utilService.getRgbFromHex(team.colour, '0.2') }}>
      <TeamPill className="shrink-0" team={team} />
      <Text className="text-xs mt-4" variant="primary-3">
        {teamService.getDisplayTeamEntityMetadata({ team, entity })}
      </Text>
      {team.code && (
        <Button
          className="relative flex items-center justify-center bg-white bg-opacity-10 rounded-full w-full py-3 px-5 mt-8"
          onClick={handleCodeClick}
          state="raw"
        >
          <div className="absolute -top-2 bg-white bg-opacity-90 rounded-full px-2">
            <Text className="font-semibold text-xs" variant="secondary-2">
              {isCopied ? 'Team code copied!' : 'Click to copy'}
            </Text>
          </div>
          <Text className="font-semibold text-2xl" variant="primary-2" isInline>
            {team.code}
          </Text>
          <Icon className="ml-4" icon="duplicate-outlined" variant="primary-3" />
        </Button>
      )}
    </div>
  )
})

import classNames from 'classnames'
import React, { ChangeEvent, forwardRef, FocusEvent } from 'react'

type Props = {
  className?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  type?: 'email' | 'number' | 'password' | 'text'
  min?: number
  max?: number
  autoComplete?: 'current-password' | 'email' | 'username' | 'given-name' | 'family-name' | 'new-password' | 'off'
  size?: 'sm' | 'md' | 'lg'
  placeholder?: string
  minLength?: number
  maxLength?: number
  value?: string | number
  pattern?: string
  title?: string
  autoFocus?: boolean
  testid?: string
  isDisabled?: boolean
  isError?: boolean
  isRequired?: boolean
}

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, size = 'md', type = 'text', testid, isError, isDisabled, isRequired, ...rest } = props
  const classes = classNames(
    className,
    'block bg-ui-900 border text-white text-opacity-90 placeholder-ui-600 rounded-md w-full focus:shadow-none focus:outline-none',
    {
      'border-ui-700 focus:border-primary-500': !isError,
      'border-danger focus:border-danger': isError,
      'coachmate-input--password': type === 'password',
      'px-2 py-0.5': size === 'sm',
      'h-11 px-3 py-2': size === 'md',
      'px-4 py-4': size === 'lg',
      'opacity-50': isDisabled,
    }
  )

  return <input {...rest} className={classes} type={type} ref={ref} disabled={isDisabled} required={isRequired} data-testid={testid} />
})

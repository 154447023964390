import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@coachmate/router'
import { AiLayout } from '@coachmate/ai'
import { AppHeader } from '@coachmate/app'

export const AppLayout = memo(() => (
  <div className="h-full pt-20">
    <AppHeader />
    <Switch>
      <Route path="/ai">
        <AiLayout />
      </Route>
      <Redirect to="/download" />
    </Switch>
  </div>
))

import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
  isFullHeight?: boolean
}

export const Section = ({ children, className, isFullHeight }: Props) => {
  const classes = classNames(className, 'relative px-8 w-full', {
    'h-full': isFullHeight,
  })
  const contentClasses = classNames('max-w-5xl mx-auto', {
    'h-full': isFullHeight,
  })

  return (
    <div className={classes}>
      <div className={contentClasses}>{children}</div>
    </div>
  )
}

type LocalStorageKey = 'firebase-token' | 'auth-redirect-route'

class LocalStorageService {
  setItem(key: LocalStorageKey, value: string) {
    if (window?.localStorage?.setItem) {
      window.localStorage.setItem(key, value)
    }
  }

  getItem(key: LocalStorageKey) {
    return window?.localStorage?.getItem ? window.localStorage.getItem(key) : ''
  }

  removeItem(key: LocalStorageKey) {
    if (window?.localStorage?.removeItem) {
      window.localStorage.removeItem(key)
    }
  }
}

export const localStorageService = new LocalStorageService()

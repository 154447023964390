import classNames from 'classnames'
import { groupBy, map } from 'lodash'
import React, { FormEvent, Fragment, memo, useEffect, useRef, useState } from 'react'
import { GqlAiChat } from '@graphql'
import { useUser } from '@coachmate/user'
import { aiChatByToken, AiChatMessage, createAiChat } from '@coachmate/ai'
import {
  Button,
  FiveHundredPage,
  Icon,
  Input,
  Page,
  Section,
  sentryService,
  Spinner,
  Text,
  useAnalytics,
  useRunQuery,
  useToast,
} from '@coachmate/common'

export const AiChatPage = memo(() => {
  const { user } = useUser()
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const [question, setQuestion] = useState('')
  const chatRef = useRef<HTMLDivElement>(null)
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const [conversationId, setConversationId] = useState('')
  const [chatIdToAnimate, setChatIdToAnimate] = useState('')
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [scrollTimer, setScrollTimer] = useState<NodeJS.Timer | null>()
  const [conversations, setConversations] = useState<Record<string, GqlAiChat[]>>({})
  const sidebarClasses = classNames(
    'fixed shrink-0 bg-ui-700 border-r border-ui-900 h-[calc(100%-80px)] overflow-y-auto scrollbar-hide w-72 px-4 py-8 transition-all z-1 md:relative md:bg-transparent md:transition-none md:h-full md:mr-4',
    {
      'md:left-0 -left-72': !isSidebarVisible,
      'left-0': isSidebarVisible,
    }
  )
  const sidebarToggleClasses = classNames('absolute border-r border-b border-ui-900 z-1 transition-all md:hidden', {
    'left-0': !isSidebarVisible,
    'left-72': isSidebarVisible,
  })

  useEffect(() => {
    ;(async () => {
      try {
        await runQuery(async () => {
          track('web_ai_chat_view')
          const chats = await aiChatByToken()
          const conversationId = chats[0]?.conversationId || ''
          const conversations = groupBy(chats, 'conversationId')

          setConversations(conversations)
          setConversationId(conversationId)
          setIsLoading(false)

          setTimeout(() => {
            chatRef.current?.addEventListener('scroll', () => {
              const chatContainer = chatRef.current

              if (!chatContainer) {
                return
              }

              if (chatContainer?.scrollHeight - chatContainer?.scrollTop - chatContainer?.clientHeight > 0) {
                setIsAtBottom(false)
              } else {
                setIsAtBottom(true)
              }
            })
            chatRef.current?.scrollTo(0, chatRef.current?.scrollHeight)
            inputRef.current?.focus()
          }, 0)
        })
      } catch (error: any) {
        setIsError(true)
        setIsLoading(false)
        track('web_ai_chat_view_error', { error: error.message })
        sentryService.captureException({ exception: error })
      }
    })()
  }, [])

  useEffect(() => {
    if (isAtBottom) {
      if (scrollTimer) {
        return
      }

      const newScrollTimer = setInterval(() => {
        chatRef.current?.scrollTo(0, chatRef.current?.scrollHeight)
      }, 300)

      setScrollTimer(newScrollTimer)
    } else {
      scrollTimer && clearTimeout(scrollTimer)
      setScrollTimer(null)
    }
  }, [isAtBottom])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitQuestion(question)
  }

  const handleSuggestionClick = (question: string) => {
    setQuestion(question)
    submitQuestion(question)
  }

  const submitQuestion = async (question: string) => {
    try {
      await runQuery(async () => {
        track('web_ai_chat_submit', { question })

        setIsSaving(true)

        const chatItem = await createAiChat({ question, conversationId: conversationId || null })

        setQuestion('')
        if (conversationId) {
          setConversations({ ...conversations, [conversationId]: [...conversations[conversationId], chatItem] })
        } else {
          setConversations({ ...conversations, [chatItem.conversationId]: [chatItem] })
          setConversationId(chatItem.conversationId)
        }

        setChatIdToAnimate(chatItem.id)
        setIsSaving(false)

        setTimeout(() => {
          inputRef.current?.focus()
        }, 0)
      })
    } catch (error: any) {
      track('web_ai_chat_submit_error', { error: error.message, question })
      sentryService.captureException({ exception: error, extras: { question } })
      openToast('Failed to load response.', 'danger')
      setChatIdToAnimate('')
      setIsSaving(false)
    }
  }

  if (!user) {
    return null
  }

  if (isError) {
    return <FiveHundredPage />
  }

  const renderSidebar = () => (
    <div className={sidebarClasses} onClick={() => setIsSidebarVisible(false)}>
      <Text className="font-semibold text-lg" variant="primary-2">
        CoachMate Assist – BETA
      </Text>
      <Text className="mt-4" variant="primary-4">
        CoachMate Assist is an AI powered coaching assistant. Get advice for questions you have about youth coaching.
      </Text>
      <Text className="mt-4" variant="primary-4">
        This feature is in beta (testing phase) so your testing will help us make it better for everyone.
      </Text>
      <Button className="w-full mt-8 mb-4" onClick={() => setConversationId('')} variant="primary" isDisabled={isLoading || isSaving}>
        + New conversation
      </Button>
      {map(conversations, (chats, id) => (
        <Button
          key={id}
          className="w-full mb-2"
          onClick={() => setConversationId(id)}
          state="outline"
          variant={conversationId === id ? 'primary' : 'neutral'}
          isDisabled={isLoading || isSaving}
        >
          <Text className="text-left w-full truncate" variant={conversationId === id ? 'primary' : 'primary-2'}>
            {chats[0].question}
          </Text>
        </Button>
      ))}
    </div>
  )

  const renderConversation = () => (
    <>
      <AiChatMessage message="Hi there! I'm CoachMate Assist, here to help answer any questions you may have about coaching youth sports. How can I help?" />
      {map(conversations[conversationId], (chatItem) => (
        <Fragment key={chatItem.id}>
          <AiChatMessage message={chatItem.question} isUser />
          <AiChatMessage message={chatItem.answer} isTyping={chatItem.id === chatIdToAnimate} onTypeEnd={() => setChatIdToAnimate('')} />
        </Fragment>
      ))}

      {isSaving && (
        <>
          <AiChatMessage message={question} isUser />
          <AiChatMessage isLoading />
        </>
      )}
    </>
  )

  const renderActions = () => (
    <div className="fixed left-0 bottom-0 bg-gradient-to-b from-transparent to-ui-900 w-full py-5 mt-auto px-5 md:sticky">
      {!conversations[conversationId]?.length && !isSaving && (
        <>
          <Text className="font-semibold mb-2" variant="primary-3">
            Suggestions
          </Text>
          <Button
            className="mr-2 mb-2"
            onClick={() => handleSuggestionClick('How do I motivate my players?')}
            variant="neutral"
            state="outline"
            height="sm"
          >
            <Text className="text-sm">How do I motivate my players?</Text>
          </Button>
          <Button
            className="mr-2 mb-2"
            onClick={() => handleSuggestionClick('How do I create a positive space?')}
            variant="neutral"
            state="outline"
            height="sm"
          >
            <Text className="text-sm">How do I create a positive space?</Text>
          </Button>
          <Button className="mb-2" onClick={() => handleSuggestionClick('How do I coach 8 year olds?')} variant="neutral" state="outline" height="sm">
            <Text className="text-sm">How do I coach 8 year olds?</Text>
          </Button>
        </>
      )}
      <div className="relative mt-6">
        <Input
          className="pr-20"
          value={question}
          onChange={({ target }) => setQuestion(target.value)}
          placeholder="Ask me anything..."
          ref={inputRef}
          maxLength={250}
          isDisabled={isSaving || isLoading}
          isRequired
        />
        <div className="absolute right-0 top-0 flex items-center justify-center h-full w-20">
          <Text className="text-xs mr-2" variant="primary-3">
            {question.length}/250
          </Text>
          {!isSaving && !isLoading && (
            <Button type="submit" state="text" isDisabled={isSaving}>
              <Icon icon="running-filled" variant="primary-1" />
            </Button>
          )}
          {(isSaving || isLoading) && <Spinner />}
        </div>
      </div>
    </div>
  )

  return (
    <form className="h-full" onSubmit={handleSubmit}>
      <Page>
        <Button className={sidebarToggleClasses} onClick={() => setIsSidebarVisible(!isSidebarVisible)} shape="square" variant="neutral" isSharp>
          <Icon icon={!isSidebarVisible ? 'menu' : 'close'} />
        </Button>
        <Section isFullHeight>
          <div className="flex flex-col h-full w-full sm:flex-row">
            {renderSidebar()}
            <div className="relative w-full px-1 py-8 overflow-y-auto scrollbar-hide" ref={chatRef}>
              {renderConversation()}
              {renderActions()}
              <div className="block h-20 sm:hidden" />
            </div>
          </div>
        </Section>
      </Page>
    </form>
  )
})

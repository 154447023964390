import { FetchPolicy, gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { TEAM_CORE_FIELDS_FRAGMENT } from '@coachmate/team/graphql/team-fragment.graphql'
import { GqlCoreTeamFieldsFragment, GqlTeamByCodeInput, GqlTeamByCodeQuery, GqlTeamByCodeQueryVariables } from '@graphql'

export const TEAM_BY_CODE = gql`
  ${TEAM_CORE_FIELDS_FRAGMENT}
  query TeamByCode($input: TeamByCodeInput!) {
    teamByCode(input: $input) {
      ...CoreTeamFields
    }
  }
`

export const teamByCode = async (input: GqlTeamByCodeInput, fetchPolicy: FetchPolicy = 'network-only') => {
  loggerService.debug('[teamByCode] Get team by code.')

  const { data } = await apolloService.query<GqlTeamByCodeQuery, GqlTeamByCodeQueryVariables>({
    query: TEAM_BY_CODE,
    variables: { input },
    fetchPolicy,
  })

  return data.teamByCode as GqlCoreTeamFieldsFragment | null
}

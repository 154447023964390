import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { AiChatPage } from '@coachmate/ai'
import { Redirect, Route } from '@coachmate/router'

export const AiLayout = memo(() => (
  <Switch>
    <Route path="/ai/chat" exact>
      <AiChatPage />
    </Route>
    <Redirect from="/ai" to="/ai/chat" />
  </Switch>
))

import { gql } from '@apollo/client'

export const ENTITY_CLUB_ORGANISATIONS_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreEntityClubOrganisationFields on EntityClubOrganisation {
    id
    name
    group
  }
`

export const ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreEntityTerminologyFields on EntityTerminology {
    drill
    drills
  }
`

export const ENTITY_CORE_FIELDS_FRAGMENT = gql`
  ${ENTITY_CLUB_ORGANISATIONS_CORE_FIELDS_FRAGMENT}
  ${ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT}
  fragment CoreEntityFields on Entity {
    id
    name
    ages
    sport
    state
    region
    description
    coverImage1x2Url
    entityPickerImage16x9Url
    logoImageUrl
    drillIds
    lessonIds
    articleIds
    focusAreaIds
    programmeIds
    miniCourseIds
    coachingTipIds
    practicePlanIds
    clubOrganisations {
      ...CoreEntityClubOrganisationFields
    }
    terminology {
      ...CoreEntityTerminologyFields
    }
  }
`

import { trim } from 'lodash'

type GetFullNameParam = {
  firstName: string
  lastName: string
}

type GetInitialsParam = GetFullNameParam

class UtilService {
  generateUid = () => {
    let date = new Date().getTime()

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      const random = (date + Math.random() * 16) % 16 | 0

      date = Math.floor(date / 16)

      return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16)
    })
  }

  toStartCase = (value: string) => {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
  }

  formatInt = (value: string | number, maximumFractionDigits = 0) => {
    return Intl.NumberFormat('en-GB', { maximumFractionDigits }).format(Number(value) || 0)
  }

  async asyncForEach<T, R = unknown>(collection: T[], callback: (item: T, index: number, collection: T[]) => Promise<R>) {
    for (let index = 0; index < collection.length; index += 1) {
      await callback(collection[index], index, collection)
    }
  }

  getUrlSearchParamByKey = (key: string) => {
    return new URLSearchParams(window.location.search).get(key)
  }

  getRgbFromHex(hex: string, a?: string | number) {
    let r = '0'
    let g = '0'
    let b = '0'

    if (hex.length === 4) {
      r = '0x' + hex[1] + hex[1]
      g = '0x' + hex[2] + hex[2]
      b = '0x' + hex[3] + hex[3]
    } else if (hex.length === 7) {
      r = '0x' + hex[1] + hex[2]
      g = '0x' + hex[3] + hex[4]
      b = '0x' + hex[5] + hex[6]
    }

    return a ? `rgba(${+r}, ${+g}, ${+b}, ${a})` : `rgb(${+r}, ${+g}, ${+b})`
  }

  getFullName({ firstName, lastName }: GetFullNameParam) {
    return trim(`${trim(firstName)} ${trim(lastName)}`)
  }

  getInitials = (user: GetInitialsParam) => {
    const fullName = this.getFullName(user)
    const names = fullName.split(' ')

    if (!names.length || !names[0]) {
      return '?'
    }

    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }

    return initials
  }
}

export const utilService = new UtilService()

import classNames from 'classnames'
import { every, find, map } from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import { TabModel, TabButton, TabsActiveIndicator } from '@coachmate/common'

export type TabsVariant = 'square' | 'rounded' | 'underline'

type Props<T> = {
  className?: string
  onChange?: (activeTab: TabModel<T>) => void
  initialTabId?: T | null
  tabs: TabModel<T>[]
  variant?: TabsVariant
  isFull?: boolean
  isDisabled?: boolean
}

export function Tabs<T>({ className, variant = 'square', initialTabId, tabs, onChange, isFull, isDisabled }: Props<T>) {
  const tabButtonRefs = useRef<HTMLButtonElement[]>([])
  const initialTabById = initialTabId && find(tabs, ({ id }) => Boolean(id && id === initialTabId))
  const [tabsToManage, setTabs] = useState<TabModel<T>[]>(tabs)
  const [activeTab, setActiveTab] = useState<TabModel<T>>(initialTabById || tabs[0])
  const classes = classNames(className, 'relative inline-flex items-center border-b border-ui-800', {
    'w-full': isFull,
    'bg-ui-700 rounded-md h-8 p-0.5': variant === 'square',
    'bg-ui-700 rounded-full p-0.5': variant === 'rounded',
  })

  useEffect(() => {
    setTabs(tabs)
    tabButtonRefs.current = []
  }, [tabs])

  useEffect(() => {
    handleSetActiveTab(activeTab)
  }, [])

  const handleSetActiveTab = (activeTab: TabModel<T>) => {
    setActiveTab(activeTab)

    if (onChange) {
      onChange(activeTab)
    }
  }

  if (!activeTab || !every(tabs, ({ id: idToCheck }) => find(tabsToManage, { id: idToCheck }))) {
    return null
  }

  return (
    <div className={classes}>
      <TabsActiveIndicator<T> tabButtonRefs={tabButtonRefs} variant={variant} activeTab={activeTab} tabs={tabsToManage} isFull={isFull} />
      {map(tabs, (tab, index) => (
        <TabButton<T>
          key={index}
          variant={variant}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          tabs={tabsToManage}
          isFull={isFull}
          isDisabled={isDisabled}
          ref={(ref) => ref && tabButtonRefs.current.push(ref)}
          {...tab}
        />
      ))}
    </div>
  )
}

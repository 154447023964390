import { find, join, upperFirst } from 'lodash'
import { GqlCoreEntityFieldsFragment, GqlCoreTeamFieldsFragment } from '@graphql'

type GetDisplayTeamEntityMetadataParam = {
  team: GqlCoreTeamFieldsFragment
  entity?: GqlCoreEntityFieldsFragment | null
}

class TeamService {
  getDisplayTeamEntityMetadata = ({ team, entity }: GetDisplayTeamEntityMetadataParam) => {
    const metadata: string[] = []

    if (!entity) {
      return ''
    }

    metadata.push(upperFirst(entity.name))

    const clubOrganisation = entity.clubOrganisations && team.clubOrganisation && find(entity.clubOrganisations, { id: team.clubOrganisation })
    clubOrganisation && metadata.push(clubOrganisation.name)

    return join(metadata, ' • ')
  }

  getDisplayTeamMetadata = ({ numberOfPlayers, age, gender }: GqlCoreTeamFieldsFragment) => {
    const metadata: string[] = []

    numberOfPlayers && metadata.push(`${numberOfPlayers} players`)
    age && metadata.push(`${age} years old`)
    gender && metadata.push(upperFirst(gender))

    return join(metadata, ' • ')
  }
}

export const teamService = new TeamService()

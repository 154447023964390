import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom'
import React, { createElement, ComponentType, memo, useEffect, useState } from 'react'
import { useAuth } from '@coachmate/auth'
import { AppErrorPage } from '@coachmate/app'
import { routerService } from '@coachmate/router'
import { userByToken, useUser } from '@coachmate/user'
import { analyticsService, localStorageService, loggerService, sentryService, useAnalytics, useRunQuery } from '@coachmate/common'

type Props = RouteProps & {
  component: ComponentType<any>
}

export const AppRoute = memo(({ component, ...rest }: Props) => {
  const history = useHistory()
  const { setUser } = useUser()
  const location = useLocation()
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticating, isAuthenticated, firebaseUser } = useAuth()

  useEffect(() => {
    ;(async () => {
      try {
        if (isAuthenticating) {
          return
        }

        if (!isAuthenticated || !firebaseUser) {
          loggerService.debug('[<AppRoute />] User is not authenticated. Redirecting to login.')

          if (location.pathname !== '/') {
            localStorageService.setItem('auth-redirect-route', location.pathname)

            return history.push(routerService.getHref('/login'))
          }

          return history.push(routerService.getHref('/download'))
        }

        await runQuery(async () => {
          const user = await userByToken()

          const { id: userId } = user
          sentryService.setUser(userId)
          analyticsService.identify(user)

          setUser(user)
          setIsLoading(false)
          track('web_app_context_load')
        })
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        setIsError(true)
        setIsLoading(false)
        track('web_app_context_load_error')
      }
    })()
  }, [isAuthenticating])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return null
        }

        if (isError) {
          return <AppErrorPage />
        }

        return createElement(component, props)
      }}
    />
  )
})

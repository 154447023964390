import { find } from 'lodash'
import { useParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useState } from 'react'
import { teamByCode, TeamCard } from '@coachmate/team'
import { entities as fetchEntities } from '@coachmate/entity'
import CoachMateLogoLight from '@assets/svg/coachmate-logo-light.svg'
import { GqlCoreEntityFieldsFragment, GqlCoreTeamFieldsFragment } from '@graphql'
import {
  Page,
  Section,
  sentryService,
  Skeleton,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  useAnalytics,
  useRunQuery,
  useTransition,
} from '@coachmate/common'

type Tab = 'new' | 'existing'
const TABS: TabModel<Tab>[] = [
  { id: 'new', label: "I'm new to CoachMate" },
  { id: 'existing', label: 'I already have an account' },
]

export const TeamJoinPage = memo(() => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { teamCode } = useParams<{ teamCode?: string }>()
  const { step, transition, onTransition } = useTransition()
  const [team, setTeam] = useState<GqlCoreTeamFieldsFragment | null>(null)
  const [entity, setEntity] = useState<GqlCoreEntityFieldsFragment | null>(null)

  // Fetching the team and entities are just to render the team card preview. If we fail to fetch these, then we will just render the page without the
  // team card preview.
  useEffect(() => {
    ;(async () => {
      await track('web_team_join_view', { teamCode })

      if (!teamCode) {
        return
      }

      try {
        await runQuery(async () => {
          const [team, entities] = await Promise.all([teamByCode({ code: teamCode }), fetchEntities()])

          if (!team) {
            return
          }

          setTeam(team)
          setEntity(find(entities, { id: team.entityId }) || null)
          setIsLoading(false)
        })
      } catch (error: any) {
        sentryService.captureException({ exception: error })
      }
    })()
  }, [])

  const renderStep = (step: number) => (
    <div className="inline-flex items-center justify-center shrink-0 border border-white border-opacity-40 rounded-full h-9 w-9 mr-4">
      <Text>{step}</Text>
    </div>
  )

  const renderNewSteps = () => (
    <>
      <div className="flex items-center mt-8">
        {renderStep(1)}
        <Text variant="primary-3">Download the CoachMate app on iOS or Android</Text>
      </div>
      <div className="text-center border border-primary-500 rounded-lg p-8 mt-5">
        <a
          href="https://onelink.to/cdysdp"
          className="block w-auto text-ui-900 font-semibold bg-primary-500 px-4 py-3 rounded"
          target="_blank"
          rel="noreferrer"
        >
          Download CoachMate
        </a>
        <div className="mt-4 text-white/90 text-sm">For iOS and Android</div>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(2)}
        <Text variant="primary-3">Create an account – it&apos;s free</Text>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(3)}
        <Text variant="primary-3">
          Select &quot;
          <Text className="font-semibold" isInline>
            Join team
          </Text>{' '}
          - with invite code&quot;
        </Text>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(4)}
        <Text variant="primary-3">Enter your invite code and join your team</Text>
      </div>
    </>
  )

  const renderExistingSteps = () => (
    <>
      <div className="flex items-center mt-8">
        {renderStep(1)}
        <Text variant="primary-3">Open the CoachMate app</Text>
      </div>

      <div className="flex items-center mt-8">
        {renderStep(2)}
        <Text variant="primary-3">
          Go to{' '}
          <Text className="font-semibold" isInline>
            &quot;Teams&quot;
          </Text>
        </Text>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(3)}
        <Text variant="primary-3">
          Select{' '}
          <Text className="font-semibold" isInline>
            &quot;+ Add&quot;
          </Text>
        </Text>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(4)}
        <Text variant="primary-3">
          Select &quot;
          <Text className="font-semibold" isInline>
            Join team
          </Text>{' '}
          - with invite code&quot;
        </Text>
      </div>
      <div className="flex items-center mt-8">
        {renderStep(5)}
        <Text variant="primary-3">Enter your invite code and join your team</Text>
      </div>
    </>
  )

  return (
    <Page>
      <Section>
        <div className="relative max-w-xl mx-auto py-8">
          <CoachMateLogoLight className="h-8 mx-auto" />

          <Text className="font-semibold text-3xl text-center mt-16">Your team {team ? team.name : ''} on CoachMate</Text>
          {isLoading && (
            <div className="flex flex-col items-center justify-center bg-ui-800 rounded-lg h-48 p-6 mt-12">
              <Skeleton className="bg-ui-700 w-1/5 h-9" isLoading isRounded />
              <Skeleton className="bg-ui-700 w-1/2 h-4 mt-4 mb-8" isLoading isRounded />
              <Skeleton className="bg-ui-700 w-full h-14" isLoading isRounded />
            </div>
          )}
          {!isLoading && team && <TeamCard className="mt-12" team={team} entity={entity} />}
          <Text className="font-semibold text-2xl text-center mt-12">How to join your team</Text>
          <Tabs<Tab>
            className="mt-8"
            tabs={TABS}
            onChange={({ id }) => onTransition({ step: id === 'new' ? 1 : 2, transition: id === 'new' ? 'left' : 'right' })}
            variant="underline"
            isFull
          />
          <AnimatePresence initial={false} mode="wait">
            {step === 1 && (
              <TransitionContainer key={1} transition={transition}>
                {renderNewSteps()}
              </TransitionContainer>
            )}
            {step === 2 && (
              <TransitionContainer key={2} transition={transition}>
                {renderExistingSteps()}
              </TransitionContainer>
            )}
          </AnimatePresence>
        </div>
      </Section>
    </Page>
  )
})

import { GqlCoreUserFieldsFragment } from '@graphql'
import { makeVar, useReactiveVar } from '@apollo/client'

const userVar = makeVar<GqlCoreUserFieldsFragment | null>(null)

export const useUser = () => {
  const user = useReactiveVar(userVar)

  const setUser = (user: GqlCoreUserFieldsFragment) => {
    userVar(user)
  }

  return {
    user,
    setUser,
  }
}

export const clearReactiveUser = () => {
  userVar(null)
}

import { toLower } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { FormEvent, useState, memo, useEffect } from 'react'
import { routerService } from '@coachmate/router'
import { sendResetPasswordEmail } from '@coachmate/user'
import { FIREBASE_AUTH_ERROR, useAuth } from '@coachmate/auth'
import { Button, Input, Label, Page, sentryService, Text, useAnalytics } from '@coachmate/common'

export const ForgotPasswordPage = memo(() => {
  const history = useHistory()
  const { logout } = useAuth()
  const { track } = useAnalytics()
  const [isSent, setIsSent] = useState(false)
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({ email: '', password: '' })

  useEffect(() => {
    ;(async () => {
      track('web_auth_forgot_password_view')
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout()
    })()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')
    setIsSaving(true)

    try {
      track('web_auth_forgot_password_submit')

      await sendResetPasswordEmail({ email: formData.email })

      setIsSent(true)
      setIsSaving(false)
    } catch (error: any) {
      if (error.code === FIREBASE_AUTH_ERROR.userNotFound) {
        setFormError('Cannot find user with that email.')
      } else {
        track('web_auth_forgot_password_submit_error', { error: error.message })
        setFormError('An unexpected error occurred.')
        sentryService.captureException({ exception: error })
      }

      setIsSaving(false)
    }
  }

  return (
    <Page isCentered isFullScreen>
      <div className="w-full sm:w-[450px] text-center px-5">
        <Text className="font-semibold text-xl mt-8">Reset password</Text>
        <Text className="text-sm mt-2" variant="primary-3">
          Or{' '}
          <Button onClick={() => history.push(routerService.getHref('/login'))} state="text" variant="primary" isDisabled={isSaving} isUnderlined>
            Go back to sign in
          </Button>
        </Text>
        {Boolean(formError) && (
          <Text className="text-sm my-4" variant="danger">
            {formError}
          </Text>
        )}
        <form className="mt-8" onSubmit={handleSubmit}>
          {isSent ? (
            <Text>Great! We&apos;ve sent you an email to reset your password.</Text>
          ) : (
            <>
              <Label>Email address</Label>
              <Input
                value={formData.email}
                onChange={({ target }) => setFormData({ ...formData, email: toLower(target.value) })}
                type="email"
                autoComplete="current-password"
                isDisabled={isSaving}
                isRequired
              />
              <Text className="text-xs mt-2" variant="primary-3">
                We will send you a reset password email, which will allow you to set a new password for your account.
              </Text>
              <Button className="mt-6 w-full" type="submit" isLoading={isSaving}>
                Send reset password email
              </Button>
            </>
          )}
        </form>
      </div>
    </Page>
  )
})

import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlCoreEntityFieldsFragment, GqlEntitiesQuery } from '@graphql'
import { ENTITY_CORE_FIELDS_FRAGMENT } from '@coachmate/entity/graphql/entity-fragment.graphql'

export const ENTITIES = gql`
  ${ENTITY_CORE_FIELDS_FRAGMENT}
  query Entities {
    entities {
      ...CoreEntityFields
    }
  }
`

export const entities = async () => {
  loggerService.debug('[entities] Get entities.')

  const { data } = await apolloService.query<GqlEntitiesQuery>({
    query: ENTITIES,
    fetchPolicy: 'network-only',
  })

  return data.entities as GqlCoreEntityFieldsFragment[]
}

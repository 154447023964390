import React from 'react'
import classNames from 'classnames'
import { Text } from '@coachmate/common'

type Props = {
  className?: string
  team: { id: string; name: string; colour: string }
  size?: 'sm' | 'md' | 'lg'
}

export const TeamPill = ({ className, team, size = 'md' }: Props) => {
  const classes = classNames(className, 'inline-flex items-center justify-between bg-white bg-opacity-10 rounded-full', {
    'max-w-4xs p-1.5 pr-3': size === 'sm',
    'max-w-3xs p-2 pr-3.5': size === 'md',
    'max-w-2xs p-2.5 pr-4': size === 'lg',
  })
  const circleClasses = classNames('shrink-0 inline-flex items-center justify-center rounded-full', {
    'w-4 h-4 mr-1': size === 'sm',
    'w-5 h-5 mr-1.5': size === 'md',
    'w-6 h-6 mr-2': size === 'lg',
  })
  const textClasses = classNames('font-semibold truncate', {
    'text-xs': size === 'sm',
    'leading-tight': size === 'md',
    'text-xl leading-tight': size === 'lg',
  })

  const renderContent = () => (
    <>
      <div className={circleClasses} style={{ backgroundColor: team.colour }} />
      <Text className={textClasses} variant="primary-1">
        {team.name}
      </Text>
    </>
  )

  return <div className={classes}>{renderContent()}</div>
}

import { includes } from 'lodash'
import classNames from 'classnames'
import React, { forwardRef, HTMLProps, MouseEvent as ReactMouseEvent } from 'react'
import { TextVariant, Text, Spinner } from '@coachmate/common'

export type ButtonVariant = 'primary' | 'neutral' | 'danger' | TextVariant
export type ButtonState = 'filled' | 'outline' | 'ghost' | 'opaque' | 'opaque-light' | 'text' | 'block' | 'input' | 'raw'
export type ButtonShape = 'rect' | 'square'
export type ButtonClickHandler = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state' | 'href'> & {
  type?: 'button' | 'submit' | 'reset'
  height?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  variant?: ButtonVariant
  state?: ButtonState
  shape?: ButtonShape
  onClick?: ButtonClickHandler
  isRounded?: boolean
  isSharp?: boolean
  isDisabled?: boolean
  isLoading?: boolean

  // Text props
  isUnderlined?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className,
    onClick,
    height = 'md',
    shape = 'rect',
    type = 'button',
    state = 'filled',
    variant = 'primary',
    isRounded,
    isSharp,
    isLoading,
    isDisabled,
    isUnderlined,
    ...rest
  } = props
  const isPrimary = variant === 'primary'
  const isNeutral = variant === 'neutral'
  const isDanger = variant === 'danger'
  let classes = classNames(className, 'text-left outline-none', {
    'pointer-events-none opacity-50': isDisabled || isLoading,
  })

  // TODO This isn't working as expected. Fix up the buttons!
  if (!includes<ButtonState>(['text', 'block', 'raw', 'input'], state)) {
    classes = classNames(classes, {
      'px-3': shape === 'rect' && includes(['xs', 'sm'], height),
      'px-4': shape === 'rect' && includes(['md', 'lg', 'xl', '2xl'], height),
      'h-6': height === 'xs',
      'h-9': height === 'sm',
      'h-11': height === 'md',
      'h-14': height === 'lg',
      'h-16': height === 'xl',
      'h-20': height === '2xl',
      'w-6': shape === 'square' && height === 'sm',
      'w-9': shape === 'square' && height === 'sm',
      'w-11': shape === 'square' && height === 'md',
      'w-14': shape === 'square' && height === 'lg',
      'w-16': shape === 'square' && height === 'xl',
      'w-20': shape === 'square' && height === '2xl',
      'rounded-lg': !isSharp && !isRounded,
      'rounded-full': !isSharp && isRounded,
    })
  }

  if (state === 'filled') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center font-semibold', {
      'bg-primary-500 text-primary-900': isPrimary,
      'bg-ui-700 text-white': isNeutral,
    })
  }

  if (state === 'outline') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center bg-ui-900 border font-semibold', {
      'border-primary-500 text-primary-500': isPrimary,
      'border-danger text-danger': isDanger,
      'border-ui-700 text-white text-opacity-60': isNeutral,
    })
  }

  if (state === 'text') {
    classes = classNames(classes, 'shrink-0 background-transparent font-normal')
  }

  if (state === 'ghost' || state === 'opaque' || state === 'opaque-light') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center font-semibold', {
      'bg-black bg-opacity-40 text-white': state === 'opaque',
      'bg-white bg-opacity-10 text-white': state === 'opaque-light',
    })
  }

  if (state === 'block') {
    classes = classNames(classes, 'w-full py-2 px-4', {
      'bg-ui-800 w-full py-2 px-4': isNeutral,
    })
  }

  if (state === 'input') {
    classes = classNames(classes, 'relative bg-ui-900 border border-ui-700 text-white text-opacity-90 rounded-md h-11 w-full px-3 py-2', {
      'border-ui-700': !isDanger,
      'border-danger': isDanger,
    })
  }

  const renderContent = () => {
    if (state === 'text') {
      return (
        <Text className={classNames({ underline: isUnderlined })} variant={variant as TextVariant}>
          {children}
        </Text>
      )
    }

    return (
      <>
        {isLoading && <Spinner className="-ml-1 mr-2" />}
        {children}
      </>
    )
  }

  return (
    <button {...rest} className={classes} type={type} disabled={isDisabled} onClick={onClick as ButtonClickHandler} ref={ref}>
      {renderContent()}
    </button>
  )
})

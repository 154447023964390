import React from 'react'
import { useHistory } from 'react-router-dom'
import { routerService } from '@coachmate/router'
import { Text, Page, Button } from '@coachmate/common'

export const AppErrorPage = () => {
  const history = useHistory()

  return (
    <Page isCentered isFullScreen>
      <div className="text-center">
        <Text className="text-center text-xl font-bold">Uh oh!</Text>

        <Text className="text-center mt-2 mb-4">Something unexpected happened.</Text>

        <Button onClick={() => history.push(routerService.getHref('/logout'))} state="text">
          Log out
        </Button>
      </div>
    </Page>
  )
}

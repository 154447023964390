import { useHistory } from 'react-router-dom'
import React, { memo, useEffect } from 'react'
import { useAuth } from '@coachmate/auth'
import { Page, Text, useAnalytics } from '@coachmate/common'

export const LogoutPage = memo(() => {
  const history = useHistory()
  const { logout } = useAuth()
  const { track } = useAnalytics()

  useEffect(() => {
    ;(async () => {
      track('web_auth_logout_view')
      await logout()
      history.push('/login')
    })()
  }, [])

  return (
    <Page isFullScreen isCentered>
      <div>
        <Text className="text-center">Logging you out...</Text>
      </div>
    </Page>
  )
})

import classNames from 'classnames'
import React, { memo, SVGProps } from 'react'
import { componentService, TextVariant } from '@coachmate/common'

import Add from '@assets/svg/icon-add.svg'
import AddCircleOutlined from '@assets/svg/icon-add-circle-outlined.svg'
import ArrowLeft from '@assets/svg/icon-arrow-left.svg'
import BellOutlined from '@assets/svg/icon-bell-outlined.svg'
import Calendar from '@assets/svg/icon-calendar.svg'
import Check from '@assets/svg/icon-check.svg'
import ChevronDown from '@assets/svg/icon-chevron-down.svg'
import ChevronLeft from '@assets/svg/icon-chevron-left.svg'
import ChevronRight from '@assets/svg/icon-chevron-right.svg'
import ChevronRightSmall from '@assets/svg/icon-chevron-right-small.svg'
import Close from '@assets/svg/icon-close.svg'
import CloseCircleFilled from '@assets/svg/icon-close-circle-filled.svg'
import DotsHorizontal from '@assets/svg/icon-dots-horizontal.svg'
import Drag from '@assets/svg/icon-drag.svg'
import DuplicateOutlined from '@assets/svg/icon-duplicate-outlined.svg'
import ExclamationCircleOutlined from '@assets/svg/icon-exclamation-circle-outlined.svg'
import Expand from '@assets/svg/icon-expand.svg'
import Export from '@assets/svg/icon-export.svg'
import Globe from '@assets/svg/icon-globe.svg'
import HeartFilled from '@assets/svg/icon-heart-filled.svg'
import HeartOutlined from '@assets/svg/icon-heart-outlined.svg'
import Hide from '@assets/svg/icon-hide.svg'
import LightbulbOutlined from '@assets/svg/icon-lightbulb-outlined.svg'
import LockClosedOutlined from '@assets/svg/icon-lock-closed-outlined.svg'
import LogoutOutlined from '@assets/svg/icon-logout-outlined.svg'
import MailOpenOutlined from '@assets/svg/icon-mail-open-outlined.svg'
import Menu from '@assets/svg/icon-menu.svg'
import MinusCircleOutlined from '@assets/svg/icon-minus-circle-outlined.svg'
import OpenBookOutlined from '@assets/svg/icon-open-book-outlined.svg'
import Pause from '@assets/svg/icon-pause.svg'
import PencilFilled from '@assets/svg/icon-pencil-filled.svg'
import Play from '@assets/svg/icon-play.svg'
import PlayCircleFilled from '@assets/svg/icon-play-circle-filled.svg'
import ProgressOutlined from '@assets/svg/icon-progress-outlined.svg'
import RunningFilled from '@assets/svg/icon-running-filled.svg'
import SearchOutlined from '@assets/svg/icon-search-outlined.svg'
import Show from '@assets/svg/icon-show.svg'
import SliderFilled from '@assets/svg/icon-slider-filled.svg'
import StarFilled from '@assets/svg/icon-star-filled.svg'
import StarOutlined from '@assets/svg/icon-star-outlined.svg'
import UserGroupOutlined from '@assets/svg/icon-user-group-outlined.svg'
import UserOutlined from '@assets/svg/icon-user-outlined.svg'
import WhistleOutlined from '@assets/svg/icon-whistle-outlined.svg'

export type IconType =
  | 'add-circle-outlined'
  | 'add'
  | 'arrow-left'
  | 'arrow-right'
  | 'bell-outlined'
  | 'calendar'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right-small'
  | 'chevron-right'
  | 'close-circle-filled'
  | 'close'
  | 'dots-horizontal'
  | 'drag'
  | 'duplicate-outlined'
  | 'exclamation-circle-outlined'
  | 'expand'
  | 'export'
  | 'globe'
  | 'heart-filled'
  | 'heart-outlined'
  | 'hide'
  | 'hide'
  | 'lightbulb-outlined'
  | 'lock-closed-outlined'
  | 'logout-outlined'
  | 'mail-open-outlined'
  | 'menu'
  | 'minus-circle-outlined'
  | 'open-book-outlined'
  | 'pause'
  | 'pencil-filled'
  | 'play-circle-filled'
  | 'play'
  | 'progress-outlined'
  | 'running-filled'
  | 'search-outlined'
  | 'show'
  | 'slider-filled'
  | 'star-filled'
  | 'star-outlined'
  | 'user-group-outlined'
  | 'user-outlined'
  | 'whistle-outlined'

type Props = {
  icon: IconType
  className?: string
  height?: number
  width?: number
  variant?: TextVariant
}

export const Icon = memo(({ className, icon, height, width, variant = 'none' }: Props) => {
  const baseClasses = classNames(className, 'inline-block', componentService.getTextClasses(variant))
  const filledIconClasses = classNames(baseClasses, 'fill-current')
  const outlinedIconClasses = classNames(baseClasses, 'stroke-current')

  const getSvgAttrs = (defaultWidth: number, defaultHeight: number) => {
    const attrs: SVGProps<SVGSVGElement> = {
      width: width || defaultWidth,
      height: height || defaultHeight,
    }

    if (attrs.width !== defaultWidth || attrs.height !== defaultHeight) {
      attrs.viewBox = `0 0 ${defaultWidth} ${defaultHeight}`
    }

    return attrs
  }

  const filledProps = { className: filledIconClasses }
  const outlinedProps = { className: outlinedIconClasses }

  switch (icon) {
    case 'add-circle-outlined':
      return <AddCircleOutlined {...filledProps} {...getSvgAttrs(20, 20)} />
    case 'add':
      return <Add {...outlinedProps} {...getSvgAttrs(14, 14)} />
    case 'arrow-left':
      return <ArrowLeft {...outlinedProps} {...getSvgAttrs(21, 10)} />
    case 'arrow-right':
      return <ArrowLeft className={classNames(outlinedIconClasses, 'rotate-180')} {...getSvgAttrs(21, 10)} />
    case 'bell-outlined':
      return <BellOutlined {...outlinedProps} {...getSvgAttrs(18, 20)} />
    case 'calendar':
      return <Calendar {...filledProps} {...getSvgAttrs(19, 20)} />
    case 'check':
      return <Check {...outlinedProps} {...getSvgAttrs(16, 13)} />
    case 'chevron-down':
      return <ChevronDown {...outlinedProps} {...getSvgAttrs(16, 9)} />
    case 'chevron-left':
      return <ChevronLeft {...outlinedProps} {...getSvgAttrs(9, 16)} />
    case 'chevron-right':
      return <ChevronRight {...outlinedProps} {...getSvgAttrs(9, 16)} />
    case 'chevron-right-small':
      return <ChevronRightSmall {...filledProps} {...getSvgAttrs(8, 14)} />
    case 'close-circle-filled':
      return <CloseCircleFilled {...filledProps} {...getSvgAttrs(20, 20)} />
    case 'close':
      return <Close {...outlinedProps} {...getSvgAttrs(14, 14)} />
    case 'dots-horizontal':
      return <DotsHorizontal {...outlinedProps} {...getSvgAttrs(18, 4)} />
    case 'drag':
      return <Drag {...filledProps} {...getSvgAttrs(10, 16)} />
    case 'duplicate-outlined':
      return <DuplicateOutlined {...filledProps} {...getSvgAttrs(18, 18)} />
    case 'exclamation-circle-outlined':
      return <ExclamationCircleOutlined {...outlinedProps} {...getSvgAttrs(20, 20)} />
    case 'expand':
      return <Expand {...filledProps} {...getSvgAttrs(14, 14)} />
    case 'export':
      return <Export {...filledProps} {...getSvgAttrs(17, 16)} />
    case 'globe':
      return <Globe {...outlinedProps} {...getSvgAttrs(20, 20)} />
    case 'heart-filled':
      return <HeartFilled {...filledProps} {...getSvgAttrs(20, 18)} />
    case 'heart-outlined':
      return <HeartOutlined {...filledProps} {...getSvgAttrs(20, 19)} />
    case 'hide':
      return <Hide {...filledProps} {...getSvgAttrs(20, 19)} />
    case 'lightbulb-outlined':
      return <LightbulbOutlined {...outlinedProps} {...getSvgAttrs(22, 21)} />
    case 'lock-closed-outlined':
      return <LockClosedOutlined {...outlinedProps} {...getSvgAttrs(18, 21)} />
    case 'logout-outlined':
      return <LogoutOutlined {...outlinedProps} {...getSvgAttrs(20, 18)} />
    case 'mail-open-outlined':
      return <MailOpenOutlined {...outlinedProps} {...getSvgAttrs(20, 20)} />
    case 'menu':
      return <Menu {...filledProps} {...getSvgAttrs(18, 16)} />
    case 'minus-circle-outlined':
      return <MinusCircleOutlined {...filledProps} {...getSvgAttrs(20, 20)} />
    case 'open-book-outlined':
      return <OpenBookOutlined {...outlinedProps} {...getSvgAttrs(21, 17)} />
    case 'pause':
      return <Pause {...filledProps} {...getSvgAttrs(12, 14)} />
    case 'pencil-filled':
      return <PencilFilled {...filledProps} {...getSvgAttrs(19, 19)} />
    case 'play-circle-filled':
      return <PlayCircleFilled {...filledProps} {...getSvgAttrs(20, 20)} />
    case 'play':
      return <Play {...filledProps} {...getSvgAttrs(11, 14)} />
    case 'progress-outlined':
      return <ProgressOutlined {...outlinedProps} {...getSvgAttrs(18, 18)} />
    case 'running-filled':
      return <RunningFilled {...filledProps} {...getSvgAttrs(14, 14)} />
    case 'search-outlined':
      return <SearchOutlined {...outlinedProps} {...getSvgAttrs(20, 20)} />
    case 'show':
      return <Show {...filledProps} {...getSvgAttrs(20, 15)} />
    case 'slider-filled':
      return <SliderFilled {...filledProps} {...getSvgAttrs(18, 10)} />
    case 'star-filled':
      return <StarFilled {...filledProps} {...getSvgAttrs(18, 18)} />
    case 'star-outlined':
      return <StarOutlined {...outlinedProps} {...getSvgAttrs(21, 21)} />
    case 'user-group-outlined':
      return <UserGroupOutlined {...outlinedProps} {...getSvgAttrs(22, 18)} />
    case 'user-outlined':
      return <UserOutlined {...outlinedProps} {...getSvgAttrs(16, 20)} />
    case 'whistle-outlined':
      return <WhistleOutlined {...outlinedProps} {...getSvgAttrs(18, 22)} />
    default:
      return <></>
  }
})

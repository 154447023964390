import classNames from 'classnames'
import React, { memo } from 'react'
import { LottieOptions, useLottie } from 'lottie-react'
import coachMateLoaderAnimationData from '@assets/lottie/coachmate-loader.lottie.json'

type LottieVariant = 'coachmate-loader'

type Props = Omit<LottieOptions, 'animationData'> & {
  className?: string
  variant?: LottieVariant
}

const getAnimationData = (variant?: LottieVariant) => {
  if (variant === 'coachmate-loader') {
    return coachMateLoaderAnimationData
  }
}

export const Lottie = memo(({ className, variant, loop = true, autoplay = true }: Props) => {
  const classes = classNames(className, 'inline-block')
  const { View } = useLottie({
    animationData: getAnimationData(variant),
    loop,
    autoplay,
  })

  return <div className={classes}>{View}</div>
})

import React, { memo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { Button, Icon, Text, useToast } from '@coachmate/common'

export type ToastVariant = 'success' | 'danger'

export type ToastProps = {
  className?: string
  children: ReactNode
  variant: ToastVariant
}

export const ToastNotification = memo(({ className, children, variant }: ToastProps) => {
  const { closeToast } = useToast()
  const classes = classNames(
    className,
    'absolute right-6 top-16 flex items-start max-w-xs w-full bg-ui-700 border overflow-hidden rounded-lg shadow-md p-2 z-toast',
    {
      'border-success': variant === 'success',
      'border-danger': variant === 'danger',
    }
  )

  const renderIcon = () => {
    switch (variant) {
      case 'success':
        return <Icon className="text-success" icon="check" />
      case 'danger':
        return <Icon className="text-danger" icon="close-circle-filled" />
    }
  }

  return ReactDOM.createPortal(
    <div className={classes}>
      <div className="flex-shrink-0 mr-3">{renderIcon()}</div>
      <div className="flex-1">
        <Text>{children}</Text>
      </div>
      <div className="ml-4 flex-shrink-0">
        <Button state="text" onClick={closeToast}>
          <Icon icon="close" variant="primary-3" />
        </Button>
      </div>
    </div>,
    document.body
  )
})

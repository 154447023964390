import { useHistory } from 'react-router-dom'
import React, { FormEvent, useState, memo, useEffect } from 'react'
import { routerService } from '@coachmate/router'
import { FIREBASE_AUTH_ERROR, useAuth } from '@coachmate/auth'
import { Button, Icon, Input, Label, localStorageService, Page, sentryService, Text, useAnalytics } from '@coachmate/common'

export const LoginPage = memo(() => {
  const history = useHistory()
  const { track } = useAnalytics()
  const { login, logout } = useAuth()
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [formData, setFormData] = useState({ email: '', password: '' })

  useEffect(() => {
    ;(async () => {
      track('web_auth_login_view')
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout()
    })()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')
    setIsSaving(true)

    try {
      track('web_auth_login_submit')

      await login(formData.email, formData.password)

      history.push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/ai/chat'))
    } catch (error: any) {
      if (error.code === FIREBASE_AUTH_ERROR.userNotFound || error.code === FIREBASE_AUTH_ERROR.wrongPassword) {
        setFormError('Email or password are incorrect.')
      } else {
        track('web_auth_login_submit_error', { error: error.message })
        setFormError('An unexpected error occurred.')
        sentryService.captureException({ exception: error })
      }

      setIsSaving(false)
    }
  }

  return (
    <Page isCentered isFullScreen>
      <div className="w-full sm:w-[450px] text-center px-5">
        <Text className="font-semibold text-xl mt-8">Sign into your account</Text>
        <Text className="text-sm mt-2" variant="primary-3">
          Don&apos;t have an account?{' '}
          <Button onClick={() => history.push(routerService.getHref('/sign-up'))} state="text" variant="primary" isDisabled={isSaving} isUnderlined>
            Create account
          </Button>{' '}
          – It&apos;s free
        </Text>
        {Boolean(formError) && (
          <Text className="text-sm my-4" variant="danger">
            {formError}
          </Text>
        )}
        <form className="mt-8" onSubmit={handleSubmit}>
          <Label>Email address</Label>
          <Input
            className="mb-4"
            value={formData.email}
            onChange={({ target }) => setFormData({ ...formData, email: target.value })}
            type="email"
            autoComplete="username"
            isError={Boolean(formError)}
            isDisabled={isSaving}
            isRequired
          />
          <Label className="mt-6">Password</Label>
          <div className="relative">
            <Input
              className="pr-10"
              value={formData.password}
              onChange={({ target }) => setFormData({ ...formData, password: target.value })}
              type={isPasswordVisible ? 'text' : 'password'}
              autoComplete="current-password"
              isError={Boolean(formError)}
              isDisabled={isSaving}
              isRequired
            />
            <Button className="absolute right-3 top-2.5" onClick={() => setIsPasswordVisible(!isPasswordVisible)} state="text" isDisabled={isSaving}>
              <Icon icon={isPasswordVisible ? 'hide' : 'show'} variant="primary-1" />
            </Button>
          </div>
          <div className="flex items-center justify-end mt-4">
            <Button
              className="text-sm"
              state="text"
              variant="primary"
              onClick={() => history.push(routerService.getHref('/forgot-password'))}
              isDisabled={isSaving}
              isUnderlined
            >
              Forgot your password?
            </Button>
          </div>
          <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
            Sign in
          </Button>
        </form>
      </div>
    </Page>
  )
})

import { gql } from '@apollo/client'

export const TEAM_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreTeamFields on Team {
    id
    entityId
    name
    colour
    code
    programmeId
    age
    skill
    gender
    numberOfPlayers
    clubOrganisation
    metadata
    status
  }
`

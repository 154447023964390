import React, { memo, useEffect } from 'react'
import iphoneOutlinePng from '@assets/images/iphone-outline.png'
import { Lottie, Page, Text, useAnalytics } from '@coachmate/common'
import CoachMateLogoLight from '@assets/svg/coachmate-logo-light.svg'
import downloadAppApplePng from '@assets/images/download-app-apple.png'
import downloadAppGooglePng from '@assets/images/download-app-google.png'

export const DownloadPage = memo(() => {
  const { init, track } = useAnalytics()

  useEffect(() => {
    ;(async () => {
      await init()
      track('web_download_coachmate_view')
    })()
  }, [])

  return (
    <Page className="bg-[#201E32]">
      <div className="text-center mx-auto p-5 my-20">
        <CoachMateLogoLight className="h-6 mx-auto" />

        <div className="relative text-center mx-auto">
          <Lottie className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-xs w-full" variant="coachmate-loader" />
          <img className="mx-auto mt-12" src={iphoneOutlinePng} alt="CoachMate animation iPhone outline" />
        </div>

        <Text className="text-2xl text-center font-semibold mt-12">Looks like you don&apos;t have CoachMate on your device</Text>

        <Text className="text-lg text-center font-semibold mt-12">Get the free app now</Text>

        <div className="flex flex-col items-center justify-center mt-5 sm:flex-row">
          <a href="https://apps.apple.com/us/app/coachmate-sport/id1600141276">
            <img src={downloadAppApplePng} alt="Download from Apple App Store" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=sport.coachmate">
            <img className="mt-5 sm:mt-0 sm:ml-5" src={downloadAppGooglePng} alt="Download from Google Play" />
          </a>
        </div>
        <Text className="text-xs text-center mt-5">CoachMate for web coming soon</Text>
      </div>
    </Page>
  )
})

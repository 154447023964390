import classNames from 'classnames'
import React, { ReactNode, MouseEvent as ReactMouseEvent, memo } from 'react'

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = {
  children: ReactNode
  className?: string
  onClick?: AnchorClickEvent | ButtonClickEvent
  isDisabled?: boolean
}

const BASE_CLASSES = [
  'relative',
  'block',
  'border-b',
  'border-ui-500',
  'outline-none',
  'w-full',
  'whitespace-nowrap',
  'px-4',
  'py-3',
  'first:rounded-t-md',
  'last:rounded-b-md',
]

export const DropdownContent = memo(({ children, className, onClick, isDisabled }: Props) => {
  const classes = classNames(className, BASE_CLASSES, {
    'cursor-pointer hover:opacity-80': onClick,
    'pointer-events-none opacity-50': isDisabled,
  })

  if (onClick) {
    return (
      <button className={classes} onClick={onClick as ButtonClickEvent} type="button">
        {children}
      </button>
    )
  }

  return <div className={classes}>{children}</div>
})

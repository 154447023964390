import { trim } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { FormEvent, useState, memo, useEffect } from 'react'
import { useAuth } from '@coachmate/auth'
import { signUpUser } from '@coachmate/user'
import { GqlSignUpUserInput } from '@graphql'
import { routerService } from '@coachmate/router'
import {
  Button,
  Checkbox,
  ERROR_MESSAGE,
  EXTERNAL_LINK,
  Icon,
  Input,
  Label,
  localStorageService,
  Page,
  Select,
  SelectOption,
  sentryService,
  Text,
  useAnalytics,
} from '@coachmate/common'

const GENDER_OPTIONS: SelectOption[] = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Let me type', value: 'let-me-type' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
]

export const SignUpPage = memo(() => {
  const history = useHistory()
  const { track } = useAnalytics()
  const { login, logout } = useAuth()
  const [gender, setGender] = useState('Female')
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isTAndCChecked, setIsTAndCChecked] = useState(false)
  const [selectedGender, setSelectedGender] = useState('Female')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isLetMeTypeEnabled, setIsLetMeTypeEnabled] = useState(false)
  const [formData, setFormData] = useState<GqlSignUpUserInput>({ firstName: '', lastName: '', email: '', password: '' })

  useEffect(() => {
    ;(async () => {
      track('web_auth_sign_up_view')
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout()
    })()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!isTAndCChecked) {
      setFormError('You must agree to the terms and conditions before you can create an account.')
      return
    }

    setFormError('')
    setIsSaving(true)
    const { firstName, lastName } = formData

    try {
      track('web_auth_sign_up_submit')
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      await signUpUser({ ...formData, firstName: trim(firstName), lastName: trim(lastName), timezone })
      await login(formData.email, formData.password)
      history.push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/ai/chat'))
    } catch (error: any) {
      track('web_auth_sign_up_submit_error', { error: error.message })

      if (error.message === ERROR_MESSAGE.accountAlreadyExists) {
        setFormError('An account with this email already exists.')
      } else if (error.message === ERROR_MESSAGE.invalidPassword) {
        setFormError('Password must be at least 6 characters.')
      } else {
        setFormError('An unexpected error occurred.')
        sentryService.captureException({ exception: error })
      }

      setIsSaving(false)
    }
  }

  const handleGenderChange = (gender: string) => {
    setSelectedGender(gender)

    if (gender === 'let-me-type') {
      setIsLetMeTypeEnabled(true)
    } else {
      setIsLetMeTypeEnabled(false)
      setGender(gender)
    }
  }

  return (
    <Page isCentered isFullScreen>
      <div className="w-full sm:w-[450px] text-center px-5">
        <Text className="font-semibold text-xl mt-8">Create account</Text>
        <Text className="text-sm mt-2" variant="primary-3">
          Already have an account?{' '}
          <Button onClick={() => history.push(routerService.getHref('/login'))} state="text" variant="primary" isDisabled={isSaving} isUnderlined>
            Sign in
          </Button>
        </Text>
        {Boolean(formError) && (
          <Text className="text-sm my-4" variant="danger">
            {formError}
          </Text>
        )}
        <form className="mt-8" onSubmit={handleSubmit}>
          <Label>Name</Label>
          <div className="flex items-start justify-between">
            <Input
              className="mr-2"
              value={formData.firstName}
              placeholder="First Name"
              onChange={({ target }) => setFormData({ ...formData, firstName: target.value })}
              autoComplete="given-name"
              isDisabled={isSaving}
              isRequired
            />

            <Input
              value={formData.lastName}
              placeholder="Last Name"
              onChange={({ target }) => setFormData({ ...formData, lastName: target.value })}
              autoComplete="family-name"
              isDisabled={isSaving}
              isRequired
            />
          </div>
          <Label className="mt-6">Gender (Optional)</Label>
          <Select options={GENDER_OPTIONS} value={selectedGender} onChange={handleGenderChange} isDisabled={isSaving} isRequired />
          {isLetMeTypeEnabled && (
            <Input
              className="mt-2"
              value={gender}
              onChange={({ target }) => setGender(target.value)}
              onFocus={({ target }) => target.select()}
              isDisabled={isSaving}
              isRequired
            />
          )}
          <Label className="mt-6">Email address</Label>
          <Input
            value={formData.email}
            onChange={({ target }) => setFormData({ ...formData, email: target.value })}
            type="email"
            autoComplete="username"
            isError={Boolean(formError)}
            isDisabled={isSaving}
            isRequired
          />
          <Label className="mt-6">Password</Label>
          <div className="relative">
            <Input
              className="pr-10"
              value={formData.password}
              onChange={({ target }) => setFormData({ ...formData, password: target.value })}
              type={isPasswordVisible ? 'text' : 'password'}
              autoComplete="current-password"
              isError={Boolean(formError)}
              isDisabled={isSaving}
              isRequired
            />
            <Button className="absolute right-3 top-2.5" onClick={() => setIsPasswordVisible(!isPasswordVisible)} state="text" isDisabled={isSaving}>
              <Icon icon={isPasswordVisible ? 'hide' : 'show'} variant="primary-1" />
            </Button>
          </div>
          <label htmlFor="coachmate-t-and-c-checkbox" className="flex flex-row cursor-pointer mt-6">
            <Checkbox
              id="coachmate-t-and-c-checkbox"
              className="mr-2"
              onChange={() => setIsTAndCChecked(!isTAndCChecked)}
              isChecked={isTAndCChecked}
              isDisabled={isSaving}
            />
            <Text className="text-sm text-left" variant="primary-3">
              By checking this box I agree to the{' '}
              <Button
                className="inline-block"
                state="text"
                variant="primary"
                onClick={() => window.open(EXTERNAL_LINK.termsOfUse, '_blank')}
                isDisabled={isSaving}
                isUnderlined
              >
                terms and conditions
              </Button>{' '}
              and{' '}
              <Button
                className="inline-block"
                state="text"
                variant="primary"
                onClick={() => window.open(EXTERNAL_LINK.privacyPolicy, '_blank')}
                isDisabled={isSaving}
                isUnderlined
              >
                privacy policy.
              </Button>
            </Text>
          </label>
          <Button className="mt-6 w-full" type="submit" isLoading={isSaving}>
            Create account
          </Button>
        </form>
      </div>
    </Page>
  )
})

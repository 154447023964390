import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

export const Header = memo(({ className, children }: Props) => {
  const classes = classNames(className, 'fixed top-0 bg-ui-900 w-full z-header')

  return (
    <header className={classes}>
      <nav className="flex items-center max-w-5xl h-20 px-8 mx-auto z-header">{children}</nav>
    </header>
  )
})

import { gql } from '@apollo/client'
import { GqlAiChatByTokenQuery } from '@graphql'
import { apolloService, loggerService } from '@coachmate/common'
import { AI_CHAT_CORE_FIELDS_FRAGMENT } from '@coachmate/ai/graphql/ai-chat-fragment.graphql'

export const AI_CHAT_BY_TOKEN = gql`
  ${AI_CHAT_CORE_FIELDS_FRAGMENT}

  query AiChatByToken {
    aiChatByToken {
      ...CoreAiChatFields
    }
  }
`

export const aiChatByToken = async () => {
  loggerService.debug('[aiChatByToken] Get ai chat by token.')

  const { data } = await apolloService.query<GqlAiChatByTokenQuery>({
    query: AI_CHAT_BY_TOKEN,
    fetchPolicy: 'network-only',
  })

  return data.aiChatByToken
}

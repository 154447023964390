import classNames from 'classnames'
import React, { memo } from 'react'
import Typewriter from 'typewriter-effect'
import { useUser } from '@coachmate/user'
import { Text, utilService } from '@coachmate/common'
import coachmateIcon from '@assets/favicon/android-chrome-192x192.png'

type Props = {
  className?: string
  message?: string
  onTypeEnd?: () => void
  isUser?: boolean
  isTyping?: boolean
  isLoading?: boolean
}

export const AiChatMessage = memo(({ className, message, onTypeEnd, isUser, isTyping, isLoading }: Props) => {
  const { user } = useUser()
  const classes = classNames(className, 'flex items-end mb-2', {
    'flex-row-reverse pl-16': isUser,
    'pr-12': !isUser,
  })
  const bubbleClasses = classNames('relative rounded-t-lg w-full p-2 last:mb-0', {
    'bg-ui-700 rounded-br-lg ml-2': !isUser,
    'bg-primary-800 rounded-bl-lg mr-2': isUser,
  })
  const bubbleChevronClasses = classNames('absolute w-0 h-0 rounded border-x-[10px] border-b-[10px] border-x-transparent bottom-0', {
    '-left-[10px] border-b-ui-700': !isUser,
    '-right-[10px] border-b-primary-800': isUser,
  })

  if (!user) {
    return null
  }

  const renderMessage = () => {
    if (isUser) {
      return message
    }

    if (isLoading) {
      return (
        <div className="relative flex items-center animate-bounce">
          <div className="absolute bg-primary-500 rounded-full animate-ping w-3 h-3" />
          <div className="relative bg-primary-500 rounded-full animate-pulse w-3 h-3" />
        </div>
      )
    }

    if (isTyping) {
      return (
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .changeDelay(8)
              .typeString(message || '')
              .callFunction(() => onTypeEnd && onTypeEnd())
              .start()
          }}
        />
      )
    }

    return message
  }

  return (
    <div className={classes}>
      {!isUser && (
        <div className="border border-ui-900 shrink-0 rounded-full overflow-hidden w-8 h-8">
          <img src={coachmateIcon} alt="CoachMate icon" />
        </div>
      )}
      <div className={bubbleClasses}>
        <div className={bubbleChevronClasses} />
        <Text className="text-primary-400 font-semibold truncate mb-1" variant={isUser ? 'primary-2' : 'none'}>
          {isUser ? utilService.getFullName(user) : 'CoachMate Assist'}
        </Text>

        <Text variant="primary-2">{renderMessage()}</Text>
      </div>
    </div>
  )
})
